<template>
    <div class="px-2">
        <div v-if="galleryImages && galleryImages.groups">
			<template v-for="(cat,catI) in galleryImages.groups">

				<a-collapse expandIconPosition="right" :key="cat+catI" class="mb-2">

					<a-collapse-panel key="1" :header="cat.name || 'Image'" :style="'background: #f7f7f7;border-radius: 4px;;overflow: hidden;'">

							<div class="img-alt-container mb-4">
							

								<p style="line-height:1" class="mt-3 mb-1">Group Name</p>
								<a-input v-model="cat.name" />
								
								<p style="line-height:1" class="mt-3 mb-1">Group Image</p>
								<ImageBoxSelector v-model="cat.image" />

								<GalleryImage :galleryImages="cat.children" @images="galleryImg" :title="'Gallery Images'" />
								
							</div>

							<a-popconfirm
                                title="Are you sure delete this image group?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="removeImage(catI)"
                            >
                                <a href="#"  class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc; border-color:var(--danger); color:var(--danger)">
                                    <a-icon type="minus-circle" /> Remove Image Group
                                </a>
                            </a-popconfirm>

					</a-collapse-panel>

				</a-collapse>
					
			</template>
			<template v-if="galleryImages && galleryImages.groups && galleryImages.groups.length <= 4">
                <div @click="addImage" class="p-3 dF jC aC cursor-pointer no-selection" style="gap:1ch; border:1px solid #ccc; background:var(--light-purple); color:var(--black); font-size:14px">
                    <a-icon type="plus-circle" />Add Image
                </div>
            </template>
			<div v-else class="p-3 dF jC aC fC cursor-pointer no-selection text-center" style="border:1px solid #ccc; background:var(--light-purple); color:var(--black); font-size:14px">
                
                <div class="dF aC" style="gap:1ch;">
                    <a-icon type="stop" /> Gallery limit reached
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
import GalleryImage from '@/components/common/GalleryImage'

export default {
    components:{ImageBoxSelector,GalleryImage},
    props:{
        value:{
            type:Array,
            required:true,
            default:[]
        },
    },
    data(){
        return {
            galleryImages:{
				groups:[
					{
						name:'Gallery 1',image:'https://unsplash.it/600/600?sidi',
						children:[]
					}
				]
			}
        }
    },
    computed:{

    },
    methods:{
		galleryImg(){
        },
        addImage(){
            let newBlock = {
				name:'Image Group',image:'https://unsplash.it/600/600?sidi',
				children:[]
			}
            if (this.limitReached){
                console.error('Limit reached for blocks')
            } else {
                console.log('else from add block ran')
                this.galleryImages.groups.push(newBlock)
            }
        },
        removeImage(i){
            this.galleryImages.groups.splice(i,1)
        }
    },
    watch:{
        galleryImages:{
            handler(val,oldVal){
				console.log('GALLERY IMAGES WATCH', val)
                return this.$emit('input', val)
            },
			deep:true
        },
    },
    created(){
        
    },
    mounted(){
        // if (!this.value) this.instance = this.instances[0].value
        if (this.value){
            console.log('CREATED THIS VALUE', this.value)
            this.galleryImages = this.value
        }
    }
}
</script>

<style scoped lang="scss">
    .button-editor-container{
        padding:10px;
        border:1px solid #eee;
        
    }
</style>