<template>
	<div class="image-holder-box"  @mouseover="showOptions = true" @mouseleave="showOptions = false">
		<div v-if="img === ''"  @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage,type:'images'})">
			<ul style="list-style-type: none; padding: 0;text-align:center;margin-top:50px">
				<li style="padding-bottom: 30px;"><a-icon type="plus" style="color:white; font-size:30px; background-color:rgb(236, 233, 241); padding:15px; border-radius:50px" /></li>
				<li>Add Gallery Images</li>
			</ul>
		</div>
		<div v-else class="relative">
			<div v-show="showOptions" class="image-selector">
				<a-button-group class="image-buttons">
					<a-button @click="$emit('previewImage',{url:img})"><a-icon type="eye" /></a-button>
					<a-button @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage,type:'images'})"><a-icon type="edit" /></a-button>
					<a-button style="color:#FD647C" @click="$emit('callback', '')"><a-icon type="delete" /></a-button>
				</a-button-group>
			</div>
			<div v-show="showOptions" style="width: 100%; height: 203px; background-color: rgba(64, 69, 76, 0.5); position: absolute;"></div>
			<div style="background-repeat: no-repeat; background-size: contain; background-position: 50%; width: 100%; height: 203px;" :style="{ 'background-image': 'url(' + img + ')' }" />
		</div>
	</div>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
	components:{ImageBoxSelector},
	props: {
		img:{
			default: '',
			type: String
		},
	},
    data() {
        return {
			showOptions: false,
			newImg: ''
        };
    },
    watch:{
        img(val){
            let self = this
            let ext = val.split('.')[val.split('.').length -1] 

            let img = document.createElement('img');
            img.onload = () => {
                console.log('width => ',img.naturalWidth);
                console.log('height => ',img.naturalHeight);
                let width = img.naturalWidth
                let height = img.naturalHeight
                if (ext !== 'svg' && (!width || !height) ) {
                    self.img = ''
                    return self.$message.error('Image Error. Not a Valid Image')
                }
                self.$emit('input',val)
                self.$emit('sized',{ext,width,height})
            }
            img.src = val;
            


        }
    },
    computed: {
        
    },
    methods: {
		selectedMarkerImage(url){
			this.newImg = url.url
			this.$emit('callback', this.newImg)
		},
    },
};
</script>

<style scoped>
.image-holder-box{
      display: table;
      width: 100%;
      height: 203px;
      vertical-align: top; 
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
  }
  .image-holder-box:hover{
      border-color: var(--orange);
      transition: border-color .3s ease;
  }
  .image-holder-box .content{
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
  }
  .image-holder-box .content .inside{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    width: 100%;
    height: 203px;
  }
  .image-selector {
	position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
	z-index: 1000;
	width: 100%;
  height:100%;
  }
  .edit-current-marker{
	position: absolute;
	background-color: var(--white);
	top: 0;
	z-index: 100;
	left: 80px;
  }
</style>